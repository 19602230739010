import React, { useContext, useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoLogOut, IoTicket } from "react-icons/io5";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../helpers/images/logo.png";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../FirebaseConfig.jsx";
import { toast } from "react-toastify";
import { MdDarkMode, MdOutlineCategory } from "react-icons/md";
import { MdLightMode } from "react-icons/md";
import { ThemeContext } from "../App.js";
import darklogo from "../helpers/images/dark-logo.png";
import { IoIosListBox } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { LuLayoutDashboard } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";

function Navbar({ viewLogin, setViewLogin, userName, setUserName }) {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleProfileHover = () => {
    setIsDropdownVisible(true);
  };

  const handleProfileLeave = () => {
    setIsDropdownVisible(false);
  };
  const handleLogout = () => {
    signOut(auth)
      .then((res) => {
        navigate("/");
        localStorage.clear();
        toast.success("Sign-out Successfully.");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserName(auth?.currentUser?.displayName);
      } else {
        setUserName(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser, userName]);
  const adminpaths = ["/adminDashboard", "/Ticket", "/Products", "/Customers"];
  const userpaths = [
    "/UserDashboard",
    "/UserProfile",
    "/UserTickets",
    "/ImmediateUserSupport",
    "/UserVideoSolutions",
  ];
  const [ToggleView, setToggleView] = useState(false);
  const toggleNavbar = () => {
    setToggleView(!ToggleView);
  };
  return (
    <nav
      className={`flex items-center justify-center sticky top-0 text-[#003C43] font-semibold bg-[#E0ECE4] dark:bg-[#0f161b] dark:text-[#5C8374] z-50 border-b border-[#003C43]`}
    >
      <ul className="flex items-center justify-between w-full p-4  ">
        <li className="w-fit flex justify-start items-center sm:visible md:hidden">
          <IoIosListBox
            size={28}
            className="cursor-pointer"
            onClick={() => toggleNavbar()}
          />
        </li>
        <div className=" md:w-auto w-full flex items-center justify-center">
          <li>
            <img
              src={theme === "dark" ? darklogo : logo}
              alt="logo"
              className="h-10 w-auto"
            />
          </li>
        </div>
        {!adminpaths.includes(location.pathname) &&
        !userpaths.includes(location.pathname) ? (
          <div className="items-center justify-around  md:flex hidden md:w-8/12 lg:w-6/12">
            <li>
              <NavLink
                to={"/"}
                activeClassName="bg-gray-700 p-2 w-full"
                className=" p-2 w-full hover:border-b-4 hover:border-[#FF4B5C] dark:border-[#5C8374]  duration-300 max-md:hidden "
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/OnlineSupport"}
                activeClassName="bg-gray-700 p-2 w-full"
                className=" p-2 w-full hover:border-b-4 hover:border-[#FF4B5C] dark:border-[#5C8374]  duration-300 max-md:hidden"
              >
                Online-Support
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/Knowledgebased"}
                activeClassName="bg-gray-700 p-2 w-full"
                className=" p-2 w-full hover:border-b-4 hover:border-[#FF4B5C] dark:border-[#5C8374] duration-300 max-md:hidden"
              >
                Knowledge-based
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/SupportTicket"}
                activeClassName="bg-gray-700 p-2 w-full"
                className=" p-2 w-full hover:border-b-4 hover:border-[#FF4B5C] dark:border-[#5C8374] duration-300 max-md:hidden"
              >
                Support-Tickets
              </NavLink>
            </li>
          </div>
        ) : null}
        <div className=" items-center justify-around w-2/12 md:flex hidden">
          <button
            className="flex items-center justify-around"
            onMouseEnter={handleProfileHover}
            onMouseLeave={handleProfileLeave}
          >
            <FaUser size={20} className="mr-1" />
            <span className="lg:flex hidden">
              {userName
                ? userName
                : localStorage.getItem("userName")
                ? localStorage.getItem("userName")
                : auth?.currentUser
                ? auth?.currentUser?.displayName
                : "Profile"}
            </span>
          </button>
          <button
            className="flex items-center justify-around cursor-pointer"
            onClick={toggleTheme}
          >
            {theme === "light" ? (
              <>
                <MdDarkMode size={20} className="mr-1" />
                <span className="lg:flex hidden ">Dark Mode</span>
              </>
            ) : (
              <>
                <MdLightMode size={28} className="mr-1" />
                <span className="lg:flex hidden">Light Mode</span>
              </>
            )}
          </button>
          {isDropdownVisible && (
            <div
              className="absolute top-1/2 grid right-0 bg-white p-2 rounded-md shadow-md w-fit text-[#056674]"
              onMouseEnter={handleProfileHover}
              onMouseLeave={handleProfileLeave}
            >
              <p>Welcome </p>
              <p className="text-xs">To access account and manage Tickets</p>
              <ul className="flex flex-col items-center justify-center text-center">
                {userName || auth?.currentUser ? (
                  <>
                    <li
                      className=" w-full p-2 hover:bg-[#E0ECE4]"
                      onClick={() => navigate("/UserProfile")}
                    >
                      View Profile
                    </li>
                    <div className="border-t-2 border-[#056674] w-full p-2 flex items-start justify-start">
                      <NavLink
                        onClick={() => {
                          handleLogout();
                        }}
                        className={
                          "p-2 flex items-center justify-center hover:bg-[#E0ECE4] hover:border border-[#056674]"
                        }
                      >
                        Log Out <IoLogOut size={20} className="ml-2" />
                      </NavLink>
                    </div>
                  </>
                ) : (
                  <>
                    <li className=" w-full p-2 hover:bg-[#E0ECE4]">
                      View Profile
                    </li>
                    <div className="border-t-2 border-[#056674] w-full p-2 flex items-start justify-start">
                      <NavLink
                        onClick={() => {
                          setViewLogin(!viewLogin);
                        }}
                        className={
                          "p-2 hover:bg-[#E0ECE4] hover:border border-[#056674]"
                        }
                      >
                        Login / SignUp
                      </NavLink>
                    </div>
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
      </ul>
      {ToggleView ? (
        <ul className="fixed inset-0 z-50 w-3/4  bg-[#ffffff] shadow-lg rounded-tr-2xl border border-[#003C43] dark:bg-[#0f161b] text-[#003C43] dark:text-[#5C8374] flex flex-col justify-between p-4">
          <div>
            <div className="flex items-center justify-between mb-4 border-b-2 border-[#003C43]">
              <span className="text-lg font-semibold opacity-50 mb-4">
                Menu
              </span>
              <IoCloseCircleSharp
                size={28}
                className="cursor-pointer mb-4"
                onClick={() => toggleNavbar()}
              />
            </div>
            {!adminpaths.includes(location.pathname) &&
            !userpaths.includes(location.pathname) ? (
              <div>
                <li>
                  <NavLink
                    to={"/"}
                    activeClassName="bg-gray-700 p-2 w-full"
                    className={({ isActive }) =>
                      `flex items-center p-2 rounded-md transition-colors duration-300 ${
                        isActive
                          ? "bg-[#E0ECE4] dark:bg-gray-700 border-b-2 border-[#056674]"
                          : "hover:bg-[#E0ECE4] dark:hover:bg-gray-700 hover:border hover:border-[#056674]"
                      }`
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/OnlineSupport"}
                    activeClassName="bg-gray-700 p-2 w-full"
                    className={({ isActive }) =>
                      `flex items-center p-2 rounded-md transition-colors duration-300 ${
                        isActive
                          ? "bg-[#E0ECE4] dark:bg-gray-700 border-b-2 border-[#056674]"
                          : "hover:bg-[#E0ECE4] dark:hover:bg-gray-700 hover:border hover:border-[#056674]"
                      }`
                    }
                  >
                    Online-Support
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/Knowledgebased"}
                    activeClassName="bg-gray-700 p-2 w-full"
                    className={({ isActive }) =>
                      `flex items-center p-2 rounded-md transition-colors duration-300 ${
                        isActive
                          ? "bg-[#E0ECE4] dark:bg-gray-700 border-b-2 border-[#056674]"
                          : "hover:bg-[#E0ECE4] dark:hover:bg-gray-700 hover:border hover:border-[#056674]"
                      }`
                    }
                  >
                    Knowledge-based
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/SupportTicket"}
                    activeClassName="bg-gray-700 p-2 w-full"
                    className={({ isActive }) =>
                      `flex items-center p-2 rounded-md transition-colors duration-300 ${
                        isActive
                          ? "bg-[#E0ECE4] dark:bg-gray-700 border-b-2 border-[#056674]"
                          : "hover:bg-[#E0ECE4] dark:hover:bg-gray-700 hover:border hover:border-[#056674]"
                      }`
                    }
                  >
                    Support-Tickets
                  </NavLink>
                </li>
              </div>
            ) : adminpaths.includes(location.pathname) ? (
              [
                {
                  to: "/adminDashboard",
                  icon: <LuLayoutDashboard size={20} className="mr-4" />,
                  label: "Dashboard",
                },
                {
                  to: "/Ticket",
                  icon: <IoTicket size={20} className="mr-4" />,
                  label: "Ticket",
                },
                {
                  to: "/Products",
                  icon: <MdOutlineCategory size={20} className="mr-4" />,
                  label: "Products",
                },
                {
                  to: "/Customers",
                  icon: <BsPeopleFill size={20} className="mr-4" />,
                  label: "Customers",
                },
              ].map((item, index) => (
                <li
                  key={index}
                  c
                  className={`w-full flex items-start rounded-md hover:bg-[#E0ECE4] dark:hover:bg-[#183D3D]  ${
                    ToggleView ? "" : "hover:border hover:border-[#056674]"
                  }`}
                >
                  <NavLink
                    to={item.to}
                    className={({ isActive }) =>
                      `flex w-full p-2 rounded-md ${
                        isActive
                          ? "dark:bg-[#183D3D] bg-[#E0ECE4] border-b-2 border-[#056674]"
                          : ""
                      }`
                    }
                  >
                    {item.icon}
                    {ToggleView && <span className="">{item.label}</span>}
                  </NavLink>
                </li>
              ))
            ) : userpaths.includes(location.pathname) ? (
              [
                {
                  to: "/UserDashboard",
                  icon: <LuLayoutDashboard size={20} className="mr-4" />,
                  label: "Dashboard",
                },
                {
                  to: "/UserProfile",
                  icon: <CgProfile size={20} className="mr-4" />,
                  label: "Profile",
                },
                {
                  to: "/UserTickets",
                  icon: <IoTicket size={20} className="mr-4" />,
                  label: "Tickets",
                },
                {
                  to: "/ImmediateUserSupport",
                  icon: <MdOutlineCategory size={20} className="mr-4" />,
                  label: "Immediate Support",
                },
                {
                  to: "/UserVideoSolutions",
                  icon: <BsPeopleFill size={20} className="mr-4" />,
                  label: "Video Solutions",
                },
              ].map((item, index) => (
                <li
                  key={index}
                  c
                  className={`w-full flex items-start rounded-md hover:bg-[#E0ECE4] dark:hover:bg-[#183D3D]  ${
                    ToggleView ? "" : "hover:border hover:border-[#056674]"
                  }`}
                >
                  <NavLink
                    to={item.to}
                    className={({ isActive }) =>
                      `flex w-full p-2 rounded-md ${
                        isActive
                          ? "dark:bg-[#183D3D] bg-[#E0ECE4] border-b-2 border-[#056674]"
                          : ""
                      }`
                    }
                  >
                    {item.icon}
                    {ToggleView && <span className="">{item.label}</span>}
                  </NavLink>
                </li>
              ))
            ) : null}
          </div>
          <div className="flex  items-center justify-around  p-2 bg-[#E0ECE4] dark:bg-[#0f161b]  rounded-md border-[#003C43]">
            <button
              className="flex items-center justify-around"
              onMouseEnter={handleProfileHover}
              onMouseLeave={handleProfileLeave}
            >
              <FaUser size={20} className="mr-1" />
              <span className="">
                {userName
                  ? userName
                  : localStorage.getItem("userName")
                  ? localStorage.getItem("userName")
                  : auth?.currentUser
                  ? auth?.currentUser?.displayName
                  : "Profile"}
              </span>
            </button>
            <button
              className="flex items-center justify-around cursor-pointer"
              onClick={toggleTheme}
            >
              {theme === "light" ? (
                <>
                  <MdDarkMode size={20} className="mr-1" />
                  <span className="">Dark Mode</span>
                </>
              ) : (
                <>
                  <MdLightMode size={28} className="mr-1" />
                  <span className="">Light Mode</span>
                </>
              )}
            </button>
            {isDropdownVisible && (
              <div
                className="absolute top-4/6 mb-48  grid bg-white p-2 rounded-md shadow-md w-full  text-[#056674]"
                onMouseEnter={handleProfileHover}
                onMouseLeave={handleProfileLeave}
              >
                <p>Welcome </p>
                <p className="text-xs">To access account and manage Tickets</p>
                <ul className="flex flex-col items-center justify-center text-center">
                  {userName || auth?.currentUser ? (
                    <>
                      <li
                        className=" w-full p-2 hover:bg-[#E0ECE4]"
                        onClick={() => navigate("/UserProfile")}
                      >
                        View Profile
                      </li>
                      <div className="border-t-2 border-[#056674] w-full p-2 flex items-start justify-start">
                        <NavLink
                          onClick={() => {
                            handleLogout();
                          }}
                          className={
                            "p-2 flex items-center justify-center hover:bg-[#E0ECE4] hover:border border-[#056674]"
                          }
                        >
                          Log Out <IoLogOut size={20} className="ml-2" />
                        </NavLink>
                      </div>
                    </>
                  ) : (
                    <>
                      <li className=" w-full p-2 hover:bg-[#E0ECE4]">
                        View Profile
                      </li>
                      <div className="border-t-2 border-[#056674] w-full p-2 flex items-start justify-start">
                        <NavLink
                          onClick={() => {
                            setViewLogin(!viewLogin);
                          }}
                          className={
                            "p-2 hover:bg-[#E0ECE4] hover:border border-[#056674]"
                          }
                        >
                          Login / SignUp
                        </NavLink>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            )}
          </div>
        </ul>
      ) : null}
    </nav>
  );
}

export default Navbar;
