import React, { useContext, useEffect, useState } from "react";
import Loader from "./Loader";
import { LoadderContext } from "../App.js";
import { RxCross1 } from "react-icons/rx";
import { FormikInput } from "../Support-sys/components/FormikInput.jsx";
import { collection, onSnapshot } from "firebase/firestore";
import { auth, db } from "../FirebaseConfig.jsx";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../Support-sys/components/Button.jsx";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
function PasswordResetForm({
  setOpenPasswordResetForm,
  openPasswordResetForm,
}) {
  const { isLoading, setIslodding } = useContext(LoadderContext);
  const [allusers, setAllusers] = useState([]);

  useEffect(() => {
    onSnapshot(collection(db, "UserDetails"), (snap) => {
      const allusers = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllusers(allusers);
    });
  }, []);
  const handleClose = () => {
    setOpenPasswordResetForm(!openPasswordResetForm);
  };
  const validateEmail = (email) => {
    const userWithEmail = allusers.find((user) => user.Email === email);
    if (!userWithEmail) {
      return "Email Is Not Register";
    }
    return undefined;
  };

  return (
    <div className="fixed inset-0 bg-cover bg-center flex overflow-auto items-center justify-center bg-black bg-opacity-70 z-50 font-Calibri">
      {isLoading && <Loader />}
      <div className="flex items-center justify-center mt-auto sm:mt-auto sm:mb-auto mb-auto w-96 lg:w-96 md:w-96 sm:w-96">
        <div className="bg-white p-4  rounded shadow-lg shadow-slate-500 w-full ">
          <div className="flex items-end justify-end ">
            <button
              className="bg-[#66BFBF] hover:bg-[#135D66] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={handleClose}
            >
              <RxCross1 />
            </button>
          </div>
          <p className="text-md font-semibold mb-6 text-center text-[#135D66]">
            {"Password Reset Form"}
          </p>
          <div>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Invalid email address")
                  .required("*required")
                  .test(
                    "checkDuplicateEmail",
                    "Email Is Not Registered",
                    (value) => !validateEmail(value)
                  ),
              })}
              onSubmit={async (values) => {
                alert(values.email);
                sendPasswordResetEmail(auth, values.email)
                  .then(() => {
                    toast.success("Password reset email sent !");
                    handleClose();
                  })
                  .catch((error) => {
                    const errorMessage = error.message;
                    toast.error(errorMessage);
                    handleClose();
                  });
              }}
            >
              {({ values, setFieldValue }) => (
                <Form className="space-y-6">
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4">
                      <FormikInput
                        name="email"
                        placeholder="Register Email Address"
                        type="email"
                        label="Enter Your Name"
                        value={values.email}
                        onChange={(event) => {
                          setFieldValue("email", event.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-6 flex justify-center">
                    <Button name="submit" type="submit" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetForm;
