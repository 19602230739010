import {
  getAuth,
  sendEmailVerification,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { db, storage } from "../FirebaseConfig";
import VerticalNavbar from "./components/VerticalNavbar";
import Navbar from "../helpers/Navbar";
import { LoginContext, UserContext } from "../App";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Loader from "../helpers/Loader";
import { toast } from "react-toastify";

function UserProfile() {
  const { viewLogin, setViewLogin } = useContext(LoginContext);
  const { userName, setUserName } = useContext(UserContext);
  const [user, setUser] = useState(null);
  const [updatedUser, setUpdatedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [ToggleView, setToggleView] = useState(false);
  const auth = getAuth();
  const [image, setImage] = useState(false);

  useEffect(() => {
    const fetchUserProfile = () => {
      const unsubscribe = onSnapshot(collection(db, "UserDetails"), (snap) => {
        const userData = snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const filteredUser = userData.find(
          (data) => data.Email === auth.currentUser?.email
        );
        setUser(filteredUser);
        setUpdatedUser(filteredUser); // Initialize updatedUser state with user data
      });

      return () => unsubscribe();
    };

    fetchUserProfile();
  }, [auth.currentUser]);
  const handleImageChange = async (file) => {
    const imageUrl = await uploadImage(file);
    console.log(imageUrl);
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      image: imageUrl,
    }));
  };
  const uploadImage = async (file) => {
    const storageRef = ref(storage, `userProfilePhoto/${file.name}`);
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    if (imageUrl) {
      setImage(false);
      toast.info("Save Your Profile");
    }
    return imageUrl;
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files[0]) {
      handleImageChange(files[0]);
    } else {
      setUpdatedUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setUpdatedUser(user); // Reset updatedUser to original user data
  };

  const handleSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    try {
      await updateDoc(doc(db, "UserDetails", user.id), updatedUser);
      updateProfile(auth?.currentUser, {
        displayName: updatedUser.Name,
      });
      // sendEmailVerification(updatedUser.Email).then(() => {
      //   toast.success("Verification email is sent to you.");
      // });
      // // updateEmail(auth.currentUser, "a")
      // //   .then((res) => {
      // //     toast.success("Email Is Updated.");
      // //   })
      // //   .catch((error) => {
      // //     const errorMessage = error.message;
      // //     toast.error(errorMessage);
      // //   });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <div className="max-sm:w-full max-md:w-full dark:bg-[#0f161b]">
      <Navbar
        viewLogin={viewLogin}
        setViewLogin={setViewLogin}
        userName={userName}
        setUserName={setUserName}
      />
      <div className="flex w-full">
        <VerticalNavbar ToggleView={ToggleView} setToggleView={setToggleView} />
        {image ? <Loader imageUrl={image} /> : null}
        <div className="flex w-full h-screen p-4 overflow-auto ">
          <div className={`bg-[#E0ECE4] dark:bg-[#040D12] w-full p-4`}>
            <div className="p-4  w-full">
              <h1 className="text-3xl text-[#056674] dark:text-[#F39422]">
                {"User Profile"}
              </h1>
              <div className="flex justify-center items-center h-full mt-2">
                <div className="bg-white dark:bg-[rgb(15,22,27)] shadow-lg dark:text-[#5C8374]  rounded-lg p-8 w-full max-w-xl ring-4 ring-red-500 ring-opacity-40">
                  {user ? (
                    <form onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 sm:grid-cols-1 items-center justify-between  mb-8">
                        <div className=" items-center justify-between">
                          <img
                            src={
                              user.image || "https://via.placeholder.com/150"
                            }
                            alt="Profile"
                            className="w-24 h-24 rounded-full object-cover mr-4"
                          />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold">
                            {user.Name || "User Name"}
                          </h2>
                          <h2 className="flex">{user.Email}</h2>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-4">
                          Profile Details
                        </h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="flex flex-col">
                            <label htmlFor="name" className="">
                              Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="Name"
                              value={updatedUser?.Name || ""}
                              onChange={handleInputChange}
                              disabled={!isEditing}
                              className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
                            />
                          </div>
                          {/* <div className="flex flex-col">
                            <label htmlFor="email" className="text-gray-600">
                              Email
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="Email"
                              value={updatedUser?.Email || ""}
                              onChange={handleInputChange}
                              disabled={!isEditing}
                              className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
                            />
                          </div> */}
                          <div className="flex flex-col">
                            <label htmlFor="phone" className="">
                              Phone
                            </label>
                            <input
                              type="text"
                              id="Mobile"
                              name="Mobile"
                              value={updatedUser?.Mobile || ""}
                              onChange={handleInputChange}
                              disabled={!isEditing}
                              className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label htmlFor="image" className="">
                              Image
                            </label>
                            <input
                              type="file"
                              id="image"
                              name="image"
                              // value={updatedUser?.image || ""}
                              onChange={(e) => {
                                handleInputChange(e);
                                setImage(true);
                              }}
                              disabled={!isEditing}
                              className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
                            />
                          </div>
                        </div>
                        {isEditing ? (
                          <div className="grid grid-cols-2 gap-4 mt-4">
                            <button
                              type="submit"
                              className={`bg-[#055674] text-white rounded-md px-2 py-2  ${
                                image ? `cursor-not-allowed` : null
                              } `}
                              disabled={image}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={handleCancelClick}
                              className="text-gray-600 rounded-md border-2 border-[#056674]"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={handleEditClick}
                            className="mt-2 hover:underline rounded-md bg-[#055674] text-white w-full px-2 py-2"
                          >
                            Edit Profile
                          </button>
                        )}
                      </div>
                    </form>
                  ) : (
                    <div className="text-center">
                      <p>Loading...</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
