import React from "react";
import { BsPeopleFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { IoTicket } from "react-icons/io5";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineCategory } from "react-icons/md";
import { NavLink } from "react-router-dom";

function VerticalNavbar({ ToggleView, setToggleView }) {
  return (
    <nav
      className={`  ${
        ToggleView ? `w-28` : `sm:w-1/6`
      } bg-white hidden md:flex  border border-[#003C43] rounded-tr-3xl dark:bg-[#040D12]  dark:text-[#5C8374] shadow-2xl min-h-screen text-[#003C43] flex-col  justify-between `}
    >
      <ul className="flex flex-col w-full p-4">
        <div className="flex justify-end w-full mb-2">
          <button onClick={() => setToggleView(!ToggleView)}>
            {ToggleView ? (
              <IoIosArrowDropright size={28} />
            ) : (
              <IoIosArrowDropleft size={28} />
            )}
          </button>
        </div>
        {[
          {
            to: "/UserDashboard",
            icon: <LuLayoutDashboard size={20} className="mr-4" />,
            label: "Dashboard",
          },
          {
            to: "/UserProfile",
            icon: <CgProfile size={20} className="mr-4" />,
            label: "Profile",
          },
          {
            to: "/UserTickets",
            icon: <IoTicket size={20} className="mr-4" />,
            label: "Tickets",
          },
          {
            to: "/ImmediateUserSupport",
            icon: <MdOutlineCategory size={20} className="mr-4" />,
            label: "Immediate Support",
          },
          {
            to: "/UserVideoSolutions",
            icon: <BsPeopleFill size={20} className="mr-4" />,
            label: "Video Solutions",
          },
        ].map((item, index) => (
          <li
            key={index}
            c
            className={`w-full flex items-start rounded-md hover:bg-[#E0ECE4] dark:hover:bg-[#183D3D]  ${
              ToggleView ? "" : "hover:border hover:border-[#056674]"
            }`}
          >
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                `flex w-full p-2 rounded-md ${
                  isActive
                    ? "dark:bg-[#183D3D] bg-[#E0ECE4] border-b-2 border-[#056674]"
                    : ""
                }`
              }
            >
              {item.icon}
              {!ToggleView && (
                <span className=" hidden lg:flex">{item.label}</span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default VerticalNavbar;
